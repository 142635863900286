@import '../../variables.scss';



.main{
    // display: none;
    width: 100%;
    position: absolute;
    top: 51px;
    bottom: 0;
    border-top: 1px solid $gridColor;
    border-bottom: 1px solid $gridColor;


    
}

.menu{
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    overflow: hidden;
    z-index: 101;
    width: 50vw;
    background: $white;
    border-left: 1px solid $gridColor;
    transition: $transitionFast;

    @media #{$mobile}{
        width: calc(100vw + 1px);
    }

    .header{
            @include fontS;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            // justify-content: center;
            height: 50px;
            border-bottom: 1px solid $gridColor;

            h3{
                @include fontSsans;
            }
    }

    .body{
        
    }

    .themeToggle{
        position: absolute;
        height: 69px;
        width: 70px;

        @media #{$mobile}{
            height: 49px;
            width: 50px;
        }
        bottom: 0px;
        right: 0;
        cursor: pointer;

        > div{
            position: absolute;
            top: 18px;
            left: 18px;
            bottom: 18px;
            right: 18px;

            @media #{$mobile}{
                top: 8px;
                left: 8px;
                bottom: 8px;
                right: 8px;
            }
            border-radius: 50%;
            border: $border;
            // border: 1px solid $color;
            border: 1px solid $darkGrayFont;
            transition: transform $transition;
            overflow: hidden;

            > div{
                height: 100%;
                width: 50%;
                position: absolute;
                // background: $black;
                // background: $color;
                background: $darkGrayFont;
            }
        }
    }

}



@keyframes loadingCounter {
    0%{
        background: $gridColor;
    }
    50%{
        background: $black;
        color: $white;
    }
    100%{
        background: $gridColor;
    }
}



.menuMainLink{
    // position: relative;
    // width: 100%;
    // height: calc((100% - 150px) / 5);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    @include fontL;
    // color: $black;
    top: 0;
    left: 0;
    display: flex;
    background: $white;
    position: relative;
    text-orientation: sideways;
    transition: none;
   
    overflow: hidden;
    padding: 27px 20px 20px 20px;
    border-bottom: 1px solid $gridColor;

    // font-family: "BurrowSansLight";
    @include fontL;
    // color: $darkGrayFont;
    transition: font-variation-settings $transition, font-size $transition;

}


.active .menuMainLink,
.menuMainLink:hover{
    color: $gridColor;
}

.overlayBackground{
    background-color: rgba(0,0,0,0.5);
    // filter: blur(13px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    visibility: none;
    z-index: 100;
    cursor: pointer;
}
