@import '../../variables.scss';
// @import '~/variables.scss';

.intro{
    position: fixed;
    // width: 100vw;
    // height: 100px;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $black;
    backface-visibility: red;


    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;

    transition:  $transitionSlow;

    h1{
        position: relative;
    }

    div{
        @include fontL;
        color: $white;
        transition: $transitionSlow;
    }
}
