
$black: var(--main-font);
$white: var(--main-bg-color);
$gray: var(--main-gray);
$darkGray: var(--main-darkGray);
$darkGrayFont: var(--main-darkGray-font);
$color: var(--main-color);

$gridColor: var(--main-gridColor);

$green: rgb(0,255,0);
$red: rgb(255,0,0);

// $padding: 12px;
$padding: 10px;
$paddingSmall: 8px;
$bezier: .25,-0.01,.25,1.02;
$bezierIcons: 0.68, -0.6, 0.32, 1.6; // easeInOutBack
// $bezier: 0.68, 0, 0.32, 1.6; // easeInOutBack edited
// $bezier: 0.87, 0, 0.13, 1; // easeInOutExpo
$bezier: 0.34, 1, 0.64, 1; // easeInOutBack
$bezier: 0.85, 0, 0.15, 1; // easeInOutCirc
$transitionSlow: 0.9s cubic-bezier($bezier);
$transition: 0.4s cubic-bezier($bezier);
$transitionFast: 0.2s cubic-bezier($bezier);


$transitionIcons: 0.4s cubic-bezier($bezierIcons);


$border: 1px solid $black;
$borderWhite: 1px solid $white;

$blackLine: rgb(50, 50, 50);

$large: "screen and (min-width: 1601px)";
$small: "screen and (max-width: 1600px)";
$extrasmall: "screen and (max-width: 1200px)";
$touch: "screen and (hover: none)";
$notouch: "screen and (hover: hover)";
// Old sizes!
// $mobile: "screen and (max-width: 1100px)";
// $nomobile: "screen and (min-width: 1101px)";
// CHECK IF NO ERRORS OPCCURE!!!!!
$mobile: "screen and (max-width: 900px)";
$tablet: "screen and (max-width: 1400px)";
$nomobile: "screen and (min-width: 901px)";


@mixin BlurBackground{
  &::before {
    content: '';
    position: absolute;
    backdrop-filter: blur(30px);
    padding-bottom: 10px;
    background-color: rgba(255,255,255, 0.2);
    border-radius: 15px;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }
  &::after {
      content: '';
      position: absolute;
      background-color: $white;
      border-radius: 15px;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      z-index: -1;
  }
}

@mixin Height {

  // height: calc(100vh - 70px);
  height: 100vh;
  // --app-height: 100%;

  @media #{$mobile}{

    // height: calc(100vh - 50px);
    // height: calc(var(--app-height) - 50px);
    // height: calc(100vh - var(--vh-offset, 0px));
    // height: calc(var(--vh) - 50px);
    height:var(--vh);
    // bottom: 100px;
    // height: var(--app-height);
  }
}

@mixin Sticky{

  position: sticky;
  top: 0px;
}


@mixin StickyOfstHeader{

  position: sticky;
  top: 50px;
}


@mixin MenuHeight {

  height: 70px;
  @media #{$mobile}{

    height: 50px;
  }

}

@mixin Button {

  position: relative;
  display: inline-block;
  // display: block;
  margin: 0 3px 3px 0;
  padding: 6px 12px 5px 12px;
  @include fontXS;
  cursor: pointer;
  border-radius: 20px;
  text-align: right;
  color: $darkGrayFont;
  background: $color;
  outline: none;
  border: none;
  transition: $transition background;
  // @include fontInvert;

  &:hover, &.isActive{
      background: $darkGray;
      background: rgba(120,120,120,0.8);
      color: $gray;
  }
}


@mixin HoverLink{

  @media #{$notouch}{
    cursor: pointer;
    transition: border-radius $transition;
    background: $white;
    // border-radius: 5px;

    &:hover{
      border-radius: 22px;
      // border-radius: 15px;
    }
  }
}


$fontFamilyVar: 'BurrowSansVariable2', arial, helvetica, sans-serif;

// $serifFont: 'GT Super Txt Trial';
$serifFont: 'GT-Super-Text-Trial-Book', Georgia, 'Times New Roman', Times, serif;
$serifFontItalic: 'GT-Super-Text-Trial-Book-Italic', Georgia, 'Times New Roman', Times, serif;
// $serifFont: 'GT Super Text Trial Regular';
// $serifFont: 'GT-Super-Text-Trial-book';

$varBlack: 200;
$varRegular: 90;
$varRegular: 120;
$varLight: 60;

@mixin fontHeadline{
  // font-size: 10vw;
  font-size: 7vw;
  line-height: 0.85;
  color: $black;

  font-family: $fontFamilyVar;
  font-variation-settings: "wght" $varBlack;

}

@keyframes squeeze {
  0%{
    font-variation-settings: "wght" $varBlack;
  }
  50%{
    font-variation-settings: "wght" $varLight;
  }
  100%{
    font-variation-settings: "wght" $varBlack;
  }
}

@mixin fontL{
  // font-size: 3.8vw;
  font-size: 4rem;

  @media #{$mobile}{
    // font-size: 5vw;
    font-size: 2rem;
  }
  line-height: 0.98;
  color: $black;
  font-family: $fontFamilyVar;
  font-variation-settings: "wght" $varBlack;
  // transition: font-size $transition !important;
}


@mixin fontML{
  font-size: clamp(30px, 4vw, 4vw);
  line-height: 1.14;
  color: $black;
  font-family: $fontFamilyVar;
  font-variation-settings: "wght" $varBlack;
}



@mixin fontM{
  font-family: $serifFont;
  font-size: 2rem;
  line-height: 1.2;

  @media #{$mobile}{
    font-size: 1.5rem;
  }
  color: $black;
  i, em{
    font-family: $serifFontItalic;
  }
}

@mixin fontS{
  // font-family: 'BurrowSansLight';
  font-family: $serifFont;
  // font-style: 'regular';
  // font-size: 13px;
  font-size: 1.15rem;
  @media #{$mobile}{
    font-size: 0.95rem;
  }
  line-height: 1.30;
  // letter-spacing: 0.1px;
  letter-spacing: 0.05rem;
  color: $black;
  i, em{
    font-family: $serifFontItalic;
  }
}

@mixin fontSsans{
    // font-size: 11px;
  font-size: 1.2rem;
  line-height: 1.1;
  color: $black;
  font-family: $fontFamilyVar;
  font-variation-settings: "wght" $varBlack;
}


// Used for menu!!
@mixin fontSblack{
  // font-size: 15px;
  font-size: 1.1rem; // Scale up a little bit
  line-height: 1.05;
  letter-spacing: 0.8px;
  color: $black;

  font-family: $fontFamilyVar;
  font-variation-settings: "wght" $varBlack;
  transition: $transition !important;
  color: red;
}

@mixin fontXS{
  // font-family: 'BurrowSansRegular';
  // font-family: 'BurrowSansLight';
  font-family: $serifFont;
  // font-size: 11px;
  font-size: 0.75rem; // Scale up a little bit
  letter-spacing: 0.03rem;
  line-height: 1.35;
  color: $black;

  font-family: $fontFamilyVar;
  font-variation-settings: "wght" $varRegular;
  transition: $transition !important;
}

@mixin fontXSblack{
  // font-size: 12px;
  font-size: 0.75rem; // Scale up a little bit
  line-height: 1.35;
  color: $black;

  font-family: $fontFamilyVar;
  font-variation-settings: "wght" $varBlack;
  transition: $transition !important;
}

@mixin fontInvert{
  color: $white;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}