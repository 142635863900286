@import '../../variables.scss';

$menuWidth: 500px;

.main{
    position: relative;
    // height: 100vh;
    @media print {
      height: unset;
      padding-bottom: 50px;
    }
    width: 100%;
    height: 100%;
    font-size: 0;
    vertical-align: top;
    top: 0;
    background-color: $white;
    overflow-x: hidden;

    @media #{$mobile}{

    
        &.isMenuOpen{
            > div:last-child{
                width: 100%;
            }
        }

    }
    @media print {
        height: unset;
        > div:first-child{
            width: 100vw;
        }
        > div:last-child{
            display: none;
        }
    }
}


.intro{
    display: flex;
    justify-content: center;
    align-items: center;
    // @include fontMblack;
    text-align: center;
    background: $white;
    color: $black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    animation: intro 6s linear forwards; 
    z-index: 10;
    > h1{

        animation: introType 5s linear forwards; 
    }
}



@keyframes intro {
    0%{
    }
    80%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
  

@keyframes introType {
    0%{
        transform: scale(0.3);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    80%{
        opacity: 1;
    }
    100%{
        transform: scale(1);
        opacity: 0;
    }
}
  
  